import { ApplicationState } from 'store';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classes from 'common/components/divContainer/style.module.scss';
import { CustomOption, customStylesWithPadding } from 'common/components/selectComponents';
import { getAllPriceEntitiesByFilter } from 'common/adapters/entities';
import { setNrgNode, setNodeOffset, setNodeInputValue } from 'pages/home/stores/slices/settings-slice'
import { AsyncPaginate, wrapMenuList } from 'react-select-async-paginate'
import toast from 'react-hot-toast';
import { components } from 'react-select';

interface Props {
    isLoadingEnabled: boolean,
    isNodeActive: boolean
}

// MenuList must be outside of Component - scrolling gets reset to top if inside Node. 
const CustomMenuList = (props: any) => {
    return (
      <>
        <div className={classes.labelContainer3ColsHeader__name}>
            <div className={classes.labelContainer3ColsHeader__left}>Name</div>
            <div className={classes.labelContainer3ColsHeader__middle}>Location</div>
            <div className={classes.labelContainer3ColsHeader__right}>Neartest Substation</div>
        </div>
        <components.MenuList {...props}>
            {props.children}
        </components.MenuList>
      </>
    );
  };
const WrappedMenuList: any = wrapMenuList(CustomMenuList);

export const Node = ({ isLoadingEnabled, isNodeActive }: Props) => {
    /**Constants */
    const dispatch = useDispatch();
    const [node_Selected, setNode_selected] = useState<any>(null);

    /**Selectors */
    const sourceId = useSelector((store: ApplicationState) => store.settingsReducer.sourceId);
    const isoId = useSelector((store: ApplicationState) => store.settingsReducer.isoId);
    const nodeIdSelected = useSelector((store: ApplicationState) => store.settingsReducer.nodeId);
    const nodeOffset = useSelector((store: ApplicationState) => store.settingsReducer.nodeOffset);
    const nodeInputValue = useSelector((store: ApplicationState) => store.settingsReducer.nodeInputValue);

    /** handlerOnInputChange */
    const handlerOnInputChangeNode = (option: any, action: any) => {
        if (isoId == null) {
            dispatch(setNodeOffset(0));
        }
        setNode_selected(option);
        dispatch(setNrgNode({ nodeId: option.value, isoName: option.iso, isoId: option.iso_id, nodeName: option.label, nodeActive: isNodeActive}));
    }

    let options = []

    const loadOptions = async (inputValue: string) => {
        let currentOffset = nodeOffset;
        if (nodeInputValue !== inputValue) {
            currentOffset = 0
            dispatch(setNodeOffset(0));
            dispatch(setNodeInputValue(inputValue))
        }

        try {
            // either getting active nodes, or all nodes(inactive/active)
            let isNodeDisabled = null;
            if (isNodeActive === true) {
                isNodeDisabled = false;
            }
            const response = await getAllPriceEntitiesByFilter(sourceId, inputValue, isoId, isNodeDisabled, currentOffset);

            options = response.data.items.map((s: any) => {
                const label = (
                    <div className={classes.labelContainer3Cols__name}>
                        <div className={classes.labelContainer3Cols__left}>{s.name}</div>
                        <div className={classes.labelContainer3Cols__middle}>{s.location_name}</div>
                        <div className={classes.labelContainer3Cols__right}>{s.substation_name ? s.substation_name : ''}</div>
                    </div>
                );
                return { value: s.id, label: s.name, iso: s.region_name, iso_id: s.region_id, shortLabel: s.name, longLabel: label };
            });
            dispatch(setNodeOffset(currentOffset + 100));
            return {
                options: options,
                hasMore: response.data.total >= nodeOffset
            };
        } catch (error) {
            toast.error("Failed to load the NRG nodes list. Please try again.");
            return { options: [], hasMore: false };
        }
    };


    useEffect(() => {
        if (nodeIdSelected == null) {
            setNode_selected(null);
        }
    }, [nodeIdSelected, node_Selected])


    return (
        // cacheUniqs will clear cache when isNodeActive is changed
        <AsyncPaginate
            id='nodeDropdown'
            key={JSON.stringify(isoId)}
            defaultOptions
            value={node_Selected}
            loadOptions={loadOptions}
            onChange={handlerOnInputChangeNode}
            className='react-select-container-nodal'
            classNamePrefix="react-select"
            components={{MenuList: WrappedMenuList, Option: CustomOption }}
            menuPlacement='auto'
            styles={customStylesWithPadding}
            isDisabled={isLoadingEnabled}
            cacheUniqs={[isNodeActive]}
        />
    )
}
